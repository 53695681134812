:root {
  --blue: #2185D0;
  --orange: #F2711C;
  --dark: #232323;
  --yellow: rgb(237 190 19);
}

#root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

#root > .ui.container {
  margin-top: 80px;
  margin-bottom: 100px;
  flex-grow: 1;
}

.button {
  border-radius: 0 !important;
}

.ui.feed > .event > .label + .content {
  margin-top: 0!important;
}
.centered {
  text-align: center!important;
}
.ui.segment.centered-background {
  background-position-y: center!important;
  background-size: cover!important;
}

.skin-overview, .author-overview {
  border-radius: 0!important;
  border: 1px solid grey!important;
  box-shadow: 4px 4px 10px 6px #e0e0e0!important;
  text-align: center;
}

.skin-overview.borderless {
  border: 0!important;
  cursor: pointer;
  box-shadow: none!important;
}
.ui.card .meta {
  color: #666!important;
  font-size: 1.2em!important;
}
.author-overview.ui.card .image, .skin-overview.ui.card .image {
  border-radius: 0!important;
}

.ui.card.skin-overview.borderful.clickable:hover {
  border: 1px solid black!important;
}
.ui.card.skin-overview.borderful:not(.clickable):hover {
  transform: none!important;
  cursor: default!important;
}
.ui.card.skin-overview.borderless:hover{
  transform: none!important;
}

.ui.card.author-overview .image img, .ui.card.skin-overview .image img {
  height: 250px;
  object-fit: cover;
}

.ui.card.skin-overview .content .price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ui.card.skin-overview .content .description {
  margin: 30px 0 50px!important;
}

.ui.card.author-overview, .ui.card.skin-overview {
  width: 100%;
}

.skin .gallery, .bundle .gallery {
  text-align: center;
  margin-top: 10px!important;
}

@media (min-width: 1200px) {
  .skin .gallery img, .bundle .gallery img {
    height: 110px!important;
    width: 162px!important;
    object-fit: cover!important;
  }
}
@media (max-width: 1200px) and (min-width: 992px) {
  .skin .gallery img, .bundle .gallery img {
    height: 100px!important;
    width: 132px!important;
    object-fit: cover!important;
  }
}
@media (max-width: 992px) {
  .skin .gallery img, .bundle .gallery img {
    height: 120px!important;
    width: 206px!important;
    object-fit: cover!important;
  }
}
.skin .gallery img:hover, .bundle .gallery img:hover {
  cursor: pointer;
}

.ui.form .field .ui.input input, .ui.selection.dropdown, .ui.selection.dropdown .menu {
  border-radius: 0!important;
}

.price span {
  color: #333;
  font-weight: bold;
}

.ui.card.author .content .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ui.card.author .content .description {
  height: 30px
}
.ui.vertical.menu {
  min-width: 12rem!important;
}
.ui.menu .item > i.icon {
  position: relative;
  top: 2px;
}
.ui.menu .active.item > i.icon {
  color: var(--orange);
}
.profile-menu-item {
  padding-left: 0!important;
}

.ui.basic.striped.table > tbody > tr:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.03)!important;
}

.ui.ui.selectable.table > tbody > tr:hover, .ui.table tbody tr td.selectable:hover {
  background: rgba(0, 0, 0, 0.06)!important;
}


@media (min-width: 600px) {
  .login-container {
    position: absolute;
    left: 50%;
    top: 45%;
    transform: translate(-50%, -50%);
    min-width: 810px;
  }
}

.ui.form .field.disabled {
  opacity: 1!important;
}

div.pagination {
  text-align: center;
  margin: 40px 0;
}

.fit-cover > img, img.fit-cover {
  object-fit: cover;
}
.clickable {
  cursor: pointer;
}
.clickable.highlight {
  font-weight: bold;
}
.highlight:not(.clickable), .clickable.highlight:hover {
  text-decoration: underline;
  text-decoration-color: var(--orange);
  text-decoration-thickness: 3px;
  text-decoration-skip-ink: none;
}
.ui.segment.payout.clickable:hover {
  box-shadow: 0 2px 8px #bbb!important;
}

.ui.pagination.menu {
  border-radius: 0;
  border: 1px solid #bbb;
}
.ui.pagination.menu .active.item {
  background-color: var(--blue)!important;
}

.ui.segment.error-msg {
  margin: 26px 0;
}

.ui.segment {
  border-radius: 0!important;
  box-shadow: none!important;
  margin: 0!important;
}

.ui.segment.footer {
  background: var(--dark);
  color: white;
  text-align: center;
  line-height: 2;
}

.footer a.icons {
  color: var(--blue)
}

.footer a.icons:hover {
  color: var(--orange);
}

.footer .links a {
  color: white;
}

.footer .links a:hover {
  color: white;
  text-decoration: underline;
  text-decoration-color: var(--orange);
  text-decoration-thickness: 3px;
  text-decoration-skip-ink: none;
}

.dropzone-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}
.file-upload.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 40px 0;
  border-color: #ddd;
  border-style: dashed;
  background-color: #fafafa;
  color: #a1a1a1;
  margin-bottom: 10px;
  outline: none;
  transition: border .24s ease-in-out;
}

.ui.form .fields.filter {
  align-items: flex-end;
  justify-content: space-between;
}

.ui.form .field.filter.right {
  flex-direction: row-reverse;
}
.roadmap .ui.list .item i.icon{
  position: relative;
  top: 2px;
}

.ui.modal {
  border-radius: 0!important;
}
.ui.modal .actions {
  border-radius: 0!important;
  background: var(--blue)!important;
  color: white;
}

.cart-overview {
  border-radius: 0!important;
}

.ui.table {
  margin-top: 20px!important;
  border-radius: 0!important;
}

.ui.message {
  border-radius: 0!important;
}

.about a, .blog p {
  line-height: 1.6;
}

.about a, .blog a {
  font-weight: bold;
}

.sotw {
  display: flex;
  margin: 0;
  border: 0;
  flex-direction: column;
  align-items: center;
  padding: 50px;
}
.sotw .preview img {
  object-fit: cover!important;
}

.ui.styled.accordion .title {
  color: rgba(0, 0, 0, 0.6) !important;
}

.editor .ui.card > .image > img {
  height: 100px!important;
  object-fit: cover;
  border-radius: 0;
}

.opacity-on-hover {
  opacity: 0.5!important;
}
.opacity-on-hover:hover {
  opacity: 1!important;
}
.hide-caret > .ui.input > input {
  caret-color: transparent;
}

.payment-methods .stripe.ui.button {
  border-radius: 4px !important;
  font-size: 1em!important;
}

.payment-methods .stripe.ui.button:hover {
  background-color: #4e42ff!important;
}

.editor-prompt .ui.card:hover {
  background-color: #2185d00f!important;
}

.editor-prompt .selected {
  background-color: #2185d00f!important;
}

.editor .ui.dropdown > .text > img, .ui.dropdown > .text > .image:not(.icon), .ui.dropdown .menu > .item > .image:not(.icon), .ui.dropdown .menu > .item > img {
  object-fit: cover!important;
  width: 4em!important;
}

.editor .ui.search.dropdown.active > input.search, .ui.search.dropdown.visible > input.search {
  font-size: 0!important;
}

textarea {
  border-radius: 0!important;
}

.form .color-picker {
  text-align: center;
}

.form .color-picker-toggle {
  margin-top: 26px;
  margin-bottom: 20px;
}

@media (max-width: 991px) {
  .top-navigation .left-items.regular {
    display: none!important;
  }
}
@media (min-width: 992px) {
  .top-navigation .left-items.icons-only {
    display: none;
  }
}

@media (min-width: 700px) {
  .top-navigation {
    height: 70px;
  }
  .profile-menu.mobile {
    display: none!important;
  }
  .ui.segment.sotw {
    padding: 50px;
  }
  .sotw > .ui.grid > [class*="eight wide"].column {
    margin: 40px 30px;
  }
  .footer {
    padding: 60px!important;
  }
}

@media only screen and (min-width: 769px) {
  .author .avatar {
    display: flex!important;
    justify-content: right!important;
  }
}

@media only screen and (max-width: 768px) {
  .login-modal.ui.modal > .actions {
    padding: 1rem!important;
  }
}

@media (max-width: 699px) {
  .profile-menu.normal {
    display: none;
  }
  .hide-on-mobile {
    display: none!important;
  }
  .ui.segment.sotw {
    padding: 50px 10px;
  }
  .sotw > .ui.grid > [class*="eight wide"].column {
    margin: 30px 0 10px;
    width: 80%!important;
  }
  .footer {
    padding: 40px 10px!important;
  }
  .footer .column {
    margin-bottom: 15px!important;
  }
}

@media (min-width: 511px) {
  .top-navigation .buttons {
    justify-content: space-between;
  }
  .tagline {
    padding: 100px 50px!important;
  }
}
@media (max-width: 510px) {
  .top-navigation {
    height: 112px;
  }
  .top-navigation .buttons {
    position: relative;
    top: 22px;
    justify-content: center;
  }
  .tagline {
    padding: 80px 20px!important;
  }
}
